import React from 'react';
import { oneOfType, string, number } from 'prop-types';
import { useStore } from '@thd-nucleus/experience-context';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { LocalAd } from './LocalAd';

export const LocalAdContainer = ({ pipTimeout, ...props }) => {

  return (
    <QueryProvider
      cacheKey="content-page"
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <LocalAd {...props} />
    </QueryProvider>
  );
};

LocalAdContainer.propTypes = {
  pipTimeout: oneOfType([string, number])
};

LocalAdContainer.defaultProps = {
  pipTimeout: '3000'
};
