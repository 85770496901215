import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import {
  QueryProvider, params, string, QueryContext, extend
} from '@thd-nucleus/data-sources';
import { Breadcrumbs } from '@thd-olt-component-react/breadcrumbs';
import { Col, Row } from '@thd-olt-component-react/grid';
import { Metadata } from '@thd-olt-component-react/metadata';
import { Heading } from '@thd-olt-component-react/heading';
import { declareContexts } from '@thd-olt-component-react/hydrator';
import { ExperienceContext, useStoreId } from '@thd-nucleus/experience-context';
import { useThdCustomer, CustomerContext } from '@thd-olt-functional/customer-information';
import { SponsoredBanner } from '@thd-olt-component-react/sponsored-content';
import flippIntegration from '../../flipp-integration/scripts/source/flippIntegration';

export const LocalAd = (props) => {
  declareContexts([QueryContext, ExperienceContext, CustomerContext]);
  const { mcvisID = '' } = useThdCustomer() || {};
  const ctx = useContext(ExperienceContext);
  const storeId = useStoreId();
  const qctx = useContext(QueryContext);
  const { channel, segment = '', isDebugMode } = ctx;
  const name = 'localad';

  const [wishabiScriptLoaded, setWishabiScriptLoaded] = useState(false);
  const staticCrumbs = [
    {
      label: 'Home',
      url: '/'
    },
    {
      label: 'Local Ad',
      url: '/c/localad'
    }
  ];

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('local-ad.page-load', { version: 'select circular' });
  }, []);

  useEffect(() => {
    const wishabiScript = document.createElement('script');
    wishabiScript.setAttribute('defer', '');
    wishabiScript.onload = () => {
      setWishabiScriptLoaded(true);
    };
    wishabiScript.src = 'https://aq.flippenterprise.net/2135/iframe.js';
    wishabiScript.id = 'flipp-storefront-script';
    document.head.appendChild(wishabiScript);
  }, []);

  useEffect(() => {
    if (wishabiScriptLoaded) {
      const iframeScript = document.createElement('script');
      iframeScript.setAttribute('defer', '');
      iframeScript.onload = () => {
        flippIntegration.createFlippIntegration(storeId);
      };
      iframeScript.src = '//weeklycirculars.homedepot.com/hosted_services/iframe.js';
      document.head.appendChild(iframeScript);
    }
  }, [wishabiScriptLoaded]);

  return (
    <>
      <Metadata data={{ canonicalURL: '/c/localad', title: 'Local Ad - The Home Depot' }} />
      <div className="grid isBound">
        <Row className="u--paddingTop isBound">
          <Col>
            <QueryProvider cacheKey="local-ad">
              <Breadcrumbs
                staticCrumbs={staticCrumbs}
              />
            </QueryProvider>
          </Col>
        </Row>
        <Row>
          <Col>
            <SponsoredBanner
              browserId={mcvisID}
              schema="localad_sponsored"
              utilizeRvData
            />
          </Col>
        </Row>
        <Row className="u--paddingLeft isBound">
          <Heading>Local Ad</Heading>
        </Row>
        <div className="grid isBound">
          <div className="col__12-12">
            <div id="flipp-container" />
          </div>
        </div>
      </div>
    </>
  );
};

LocalAd.displayName = 'LocalAd';

LocalAd.propTypes = {};

LocalAd.dataModel = extend(
  {
    contentListingPage: params({ name: string().isRequired() }).shape({
      name: string(),
      templates: string()
    })
  },
  SponsoredBanner,
);
