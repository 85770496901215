const flippIntegrationModule = {};
const ACCESS_TOKEN = '4c1ca51353684ca4e164176a2363d7bf';
const MERCHANT_ID = '2135';
const MERCHANT_NAME_ID = 'homedepotusa';
const LOCALE = 'en-US';

flippIntegrationModule.initializeFlippContainer = function initializeFlippContainer(config) {

  const flippContainer = document.querySelector('#flipp-container');
  /* eslint-disable no-undef */
  const storefront = new Flipp.Storefront({
    accessToken: ACCESS_TOKEN,
    merchantId: MERCHANT_ID,
    merchantNameIdentifier: MERCHANT_NAME_ID,
    locale: LOCALE,
    storeCode: config.storeId
  });

  /* eslint-enable no-undef */
  storefront.renderStorefront(flippContainer);

  return storefront;
};

flippIntegrationModule.createFlippIntegration = function createFlippIntegration(storeId) {
  flippIntegrationModule.initializeFlippContainer({
    storeId
  });
};

export default flippIntegrationModule;